import request from '@/utils/request'

const property_billApi = {
  property_bill_list: '/property_bill/',
  property_bill_create: '/property_bill/',
  property_bill_update: '/property_bill/',
  property_bill_delete: '/property_bill/',
  property_bill_audit: '/property_bill/audit/',
  property_bill_manual_create: '/property_bill/',
  property_arrears: '/property/arrears/',
  property_arrears_export: '/property/arrears/export/',
  property_summary_statistics: '/property/summary_statistics/v2/',
  asset_rental_rate_summary_statistics: '/asset_rental_rate/summary_statistics/',
  property_bill_excel_list: '/property_bill/export/',
  guarantee_bill_manual_create: '/property_bill/'
}

/**
 * 列表
 */
export function property_bill_list (parameter) {
  return request({
    url: property_billApi.property_bill_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function property_bill_create (parameter) {
  return request({
    url: property_billApi.property_bill_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 修改
 */
export function property_bill_update (parameter, property_bill_id) {
  return request({
    url: property_billApi.property_bill_update + property_bill_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function property_bill_delete (property_bill_id) {
  return request({
    url: property_billApi.property_bill_delete + property_bill_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 手动添加物业账单
 */
export function manual_property_bill_create (parameter) {
  return request({
    url: property_billApi.property_bill_manual_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 企业欠款列表
 */
export function property_arrears (parameter) {
  return request({
    url: property_billApi.property_arrears,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 企业欠款导出
 */
export function property_arrears_export (parameter) {
  return request({
    url: property_billApi.property_arrears_export,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}

/**
 * 统计结果
 */
export function property_summary_statistics (parameter) {
  return request({
    url: property_billApi.property_summary_statistics,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 资产出租率
 */
export function asset_rental_rate_summary_statistics (parameter) {
  return request({
    url: property_billApi.asset_rental_rate_summary_statistics,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 导出
 */
export function property_bill_excel_list (parameter) {
  return request({
    url: property_billApi.property_bill_excel_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}

/**
 * 审核
 */
export function property_bill_audit (parameter) {
  return request({
    url: property_billApi.property_bill_audit,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
