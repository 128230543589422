<template>
  <a-modal
    title="审核"
    :width="380"
    :visible="visible"
    @cancel="() => { $emit('cancel') }"
  >
    <template slot="footer">
      <a-button key="fail" type="danger" :loading="loading" @click="() => { $emit('ok','no') }">
        不通过
      </a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="() => { $emit('ok','yes') }">
        通过
      </a-button>
    </template>
    <span style="text-align: left">租金账单已创建，审核是否通过？</span>
  </a-modal>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      color: '#3f3f3f'
    }
  },
  created () {
  },
  methods: {
  }
}
</script>
