import request from '@/utils/request'

const rent_billApi = {
  rent_bill_list: '/rent_bill/',
  rent_bill_create: '/rent_bill/',
  rent_bill_update: '/rent_bill/',
  rent_bill_delete: '/rent_bill/',
  rent_bill_manual_create: '/rent_bill/',
  bill_statistics: '/bill_statistics/',
  bill_payment_statistics: '/bill_payment_statistics/',
  rent_bill_excel_list: '/rent_bill/export/',
  rent_bill_reduce_all_list: '/rent_bill/reduce/',
  rent_bill_reduce_list: '/rent_bill/id/reduce/',
  rent_bill_reduce_create: '/rent_bill/id/reduce/',
  rent_bill_reduce_delete: '/rent_bill/id/reduce/',
  rent_bill_reduce_rent_bill_create: '/rent_bill/id/reduce/reduce_id/',
  guarantee_bill_list: '/guarantee_bill/',
  guarantee_bill_manual_create: '/guarantee_bill/',
  guarantee_bill_approval: '/user/approve/todo/1/'
}

/**
 * 列表
 */
export function rent_bill_list (parameter) {
  return request({
    url: rent_billApi.rent_bill_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function rent_bill_create (parameter) {
  return request({
    url: rent_billApi.rent_bill_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function rent_bill_update (parameter, rent_bill_id) {
  return request({
    url: rent_billApi.rent_bill_update + rent_bill_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function rent_bill_delete (rent_bill_id) {
  return request({
    url: rent_billApi.rent_bill_delete + rent_bill_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 手动添加租金账单
 */
export function manual_rent_bill_create (parameter) {
  return request({
    url: rent_billApi.rent_bill_manual_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 列表
 */
export function bill_statistics_list (parameter) {
  return request({
    url: rent_billApi.bill_statistics,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 列表
 */
export function bill_payment_statistics (parameter) {
  return request({
    url: rent_billApi.bill_payment_statistics,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 导出
 */
export function rent_bill_excel_list (parameter) {
  return request({
    url: rent_billApi.rent_bill_excel_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}

/**
 * 添加减免
 */
export function rent_bill_reduce_all_list (query) {
  return request({
    url: rent_billApi.rent_bill_reduce_all_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: query
  })
}

/**
 * 添加减免
 */
export function rent_bill_reduce_list (id) {
  return request({
    url: rent_billApi.rent_bill_reduce_list.replace('id', id),
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 添加减免
 */
export function rent_bill_reduce_create (id, parameter) {
  return request({
    url: rent_billApi.rent_bill_reduce_create.replace('id', id),
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除减免
 */
export function rent_bill_reduce_delete (id) {
  return request({
    url: rent_billApi.rent_bill_reduce_delete.replace('id', id),
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 添加减免
 */
export function rent_bill_reduce_rent_bill_create (id, reduce_id, parameter) {
  return request({
    url: rent_billApi.rent_bill_reduce_rent_bill_create.replace('id', id).replace('reduce_id', reduce_id),
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 手动添加保证金金账单
 */
export function guarantee_bill_manual_create (parameter) {
  return request({
    url: rent_billApi.guarantee_bill_manual_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 列表
 */
export function guarantee_bill_list (parameter) {
  return request({
    url: rent_billApi.guarantee_bill_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 删除减免
 */
export function guarantee_bill_delete (id) {
  return request({
    url: rent_billApi.guarantee_bill_list + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 查看保证金审批
 */
export function guarantee_bill_approval (parameter) {
  return request({
    url: rent_billApi.guarantee_bill_approval,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
