<template>
  <a-modal
    title="导出"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok',exportTime) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="企业名称">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'corporation_id',
                  {
                    rules: [
                      { required: true, message: '必填项，请填写信息' },
                    ],
                  },
                ]"
                placeholder="请输入企业名称"
                :default-active-first-option="false"
                @search="corporationSearch"
                @change="corporationChange"
              >
                <a-select-option v-for="d in dataSource" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="时间">
              <a-range-picker
                @change="time"
                format="YYYY-MM-DD HH:mm:ss"
                v-decorator="[
                  'time',
                  {
                    rules: [
                      { required: true, message: '必填项，请填写信息' },
                    ],
                  },
                ]">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { corporation_list } from '@/api/corporation'
import { group_list } from '@/api/electricity-meter-group'
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      exportTime: {},
      form: this.$form.createForm(this),
      groupData: [],
      dataSource: []
    }
  },
  created () {
    this.groupList(undefined)
  },
  methods: {
    groupList (obj) {
      group_list(obj)
        .then(res => {
          this.groupData = res.data.entries
        })
    },
    handleGroupChange (value) {
      console.log(value)
    },
    groupSearch (value) {
      console.log(value)
      this.groupList({ number: value })
    },
    // 公司
    corporationSearch (value) {
      corporation_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        // this.dataSource = !value ? [] : result
        this.dataSource = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    corporationChange (value) {
      console.log(value)
      if (value === undefined) {
        this.corporationSearch(value)
      } else {
        this.exportTime.corporation_id = value
      }
    },
    propertyOwnerChange (value) {
      console.log(value)
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      if (dateStrings && dateStrings.length === 2) {
        this.exportTime.start_time = dateStrings[0]
        this.exportTime.end_time = dateStrings[1]
      }
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    }
  }
}
</script>
